import React from 'react'
import SbEditable from 'storyblok-react'
import { Button as ACSButton } from '@system'

const Button = ({ blok, ...props }) => {
  const newProps = { ...props, ...blok }
  return (
    <SbEditable content={blok}>
      <ACSButton {...newProps} />
    </SbEditable>
  )
}
export default Button
